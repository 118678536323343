import Shortcut, { initShortcuts, deinitShortcuts } from "./shortcut";
import Commands from "./commands/commands";
import Document from "./document";
import History from "./history";

const Editor = {
    editor: document.createElement("div"),
    callbacks: {},

    init: function (root) {
        this.editor.id = "tinymde-editor";
        this.editor.contentEditable = true;
        this.editor.spellcheck = false;

        Document.init(this.editor);

        root.append(this.editor);
        initShortcuts(this.editor);
        dispatchCallbacks(this.editor, this.callbacks);
        clipboardPaste(this.editor);
        addShortcuts.apply(this);

        if (!("content" in this)) {
            Object.defineProperty(this, "content", {
                get: function () {
                    return this.editor.innerText;
                },
                set: function (val) {
                    Document.reset(val);
                    Document.render(this.editor);
                },
                configurable: false,
                enumerable: false,
            });
        }

        this.editor.addEventListener("keydown", (event) => {
            if (event.ctrlKey && /[a-zA-Z]/.test(event.key)) return;

            if (event.key === "Enter") {
                event.preventDefault();
                Document.insertParagraph();
            }

            if (event.key === "Backspace") {
                if (Document.removeParagraph()) {
                    event.preventDefault();
                }
            }

            if (
                String.fromCharCode(event.keyCode).match(/(\w|\s|À|~)/g) ||
                event.key === "Backspace"
            ) {
                // if character key, update
                setTimeout(() => {
                    Document.update(event.key);
                });
            }
        });

        this.editor.focus();
    },

    addEventListener: function (event, fn) {
        this.callbacks[event] = fn;
    },

    executeCommand: function (command, value) {
        this.editor.focus();
        switch (command) {
            case "bold":
                Commands.Bold.execute();
                break;
            case "strikethrough":
                Commands.Strikethrough.execute();
                break;
            case "italic":
                Commands.Italic.execute();
                break;
            case "header":
                Commands.Header.execute(value);
                break;
            case "unordered-list":
                Commands.UnorderedList.execute();
                break;
            case "ordered-list":
                Commands.OrderedList.execute();
                break;
            case "blockquote":
                Commands.Blockquote.execute();
                break;
            case "link":
                break;
            default:
                console.error(`TinyMDE: ${command} is an invalid command.`);
                break;
        }
        Document.update();
    },

    destroy: function () {
        deinitShortcuts();
    },
};

export default Editor;

function dispatchCallbacks(editor, callbacks) {
    editor.onkeypress = (event) => {
        if ("onkeypress" in callbacks) {
            callbacks.onkeypress(event);
        }
    };
    editor.onmousemove = (event) => {
        if ("onmousemove" in callbacks) {
            callbacks.onmousemove(event);
        }
    };
    editor.onkeyup = (event) => {
        if ("onkeyup" in callbacks) {
            callbacks.onkeyup(event);
        }
    };
}

function addShortcuts() {
    Shortcut("Tab", () => {
        // TODO: replace with a local command
        document.execCommand("insertHTML", false, "&nbsp".repeat(4));
    });
    Shortcut("ctrl+z", () => History.undo());
    Shortcut("ctrl+shift+z", () => History.redo());
    Shortcut("ctrl+b", () => this.executeCommand("bold"));
    Shortcut("ctrl+i", () => this.executeCommand("italic"));
    Shortcut("ctrl+k", () => this.executeCommand("link"));
    Shortcut("ctrl+1", () => this.executeCommand("header", 1));
    Shortcut("ctrl+2", () => this.executeCommand("header", 2));
    Shortcut("ctrl+3", () => this.executeCommand("header", 3));
    Shortcut("ctrl+4", () => this.executeCommand("header", 4));
    Shortcut("ctrl+5", () => this.executeCommand("header", 5));
    Shortcut("ctrl+6", () => this.executeCommand("header", 6));
    Shortcut("ctrl+0", () => this.executeCommand("blockquote"));
    Shortcut("ctrl+l", () => this.executeCommand("unordered-list"));
    Shortcut("ctrl+shift+l", () => this.executeCommand("ordered-list"));
}

function clipboardPaste(editor) {
    editor.onpaste = (event) => {
        event.preventDefault();
        if (event.clipboardData) {
            const text = event.clipboardData.getData("text/plain");
            document.execCommand("insertText", false, text);
            Document.reset(editor.innerText);
        }
    };
}
