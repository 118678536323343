import { selectContentInActiveRange, getSurroundingWord } from "../utils/text";
import { getElementWithNid, getSelection } from "../document";
import History, { makeLocalExecRecord } from "../history";

import insertText from "../utils/text";

const Strikethrough = {
    regex: /(~{2})(.+?)(\1)/g,
    offset: 2,
    execute: function () {
        const info = getSelection();
        if (!info) return;

        const para = getElementWithNid(info.startNode);
        if (!para) return;

        const text = info.startContainer?.wholeText || "";

        History.push(makeLocalExecRecord(info.startNode, "strikethrough"));

        const addMarkdown = () => {
            let selectionRange = {
                start: info.startOffset,
                end: info.endOffset,
            };
            if (info.isCollapsed) {
                selectionRange = getSurroundingWord(text, info.startOffset);
            }
            const selection = selectContentInActiveRange(selectionRange);
            insertText(info.parentNode, `~~${selection}~~`);
        };

        const stripMarkdown = (match) => {
            info.parentNode.innerText = info.parentNode.innerText.replace(
                match,
                match.replace(this.regex, "$2")
            );
        };

        const match = text.match(this.regex);
        match ? stripMarkdown(match[0]) : addMarkdown();
    },
};

export default Strikethrough;
