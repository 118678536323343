import Cursor from "./utils/cursor";
import Document, { getActiveParagraph, getElementWithNid } from "./document";
import Commands from "./commands/commands";

const History = {
    u: [],
    r: [],
    didRedo: false,

    push: function (cmd) {
        // a redo execution generates a new push.
        // if that's the case, don't clear the redo stack.
        if (!this.didRedo) {
            this.r = [];
        } else {
            this.didRedo = false;
        }
        this.u.push(cmd);
    },

    pop: function () {
        this.didRedo = true;
        if (this.u.length) {
            this.u.pop();
        }
    },

    undo: function () {
        if (!this.u.length) {
            return;
        }
        const cmd = this.u.pop();
        this.r.push(cmd);
        processExecRecord("undo", cmd);
    },

    redo: function () {
        if (!this.r.length) {
            return;
        }
        const cmd = this.r.pop();
        this.u.push(cmd);
        processExecRecord("redo", cmd);
    },
};

export default History;

export function makeLocalExecRecord(nid, command, metadata = {}) {
    const para = getElementWithNid(nid);
    if (!para) return;
    const cursor = Cursor.getCurrentCursorPosition(para);
    return {
        nid,
        command,
        scope: "local",
        metadata,
        undo: {
            data: para.innerText,
            cursor,
        },
        redo: {
            cursor,
        },
    };
}

export function makeGlobalExecRecord(nid, command, metadata = {}) {
    const para = getActiveParagraph();
    if (!para) return;
    const cursor = Cursor.getCurrentCursorPosition(para);
    return {
        nid,
        command,
        scope: "global",
        metadata,
        undo: {
            data: Document.copy(),
            cursor,
        },
        redo: {
            cursor,
        },
    };
}

function processExecRecord(op, record) {
    let el = getElementWithNid(record.nid);
    if (!el) return;

    if (op === "undo") {
        if (record.scope === "local") {
            el.innerText = record[op].data;
            Document.update();
        } else if (record.scope === "global") {
            Document.load(record[op].data);
        }
        el = getElementWithNid(record.nid);
        Cursor.setCurrentCursorPosition(record[op].cursor, el);
    }

    if (op === "redo") {
        Cursor.setCurrentCursorPosition(record[op].cursor, el);
        History.pop();

        const execute = () => {
            // global execution records

            if (record.command === "insert-paragraph") {
                Document.insertParagraph();
            }

            if (record.command === "remove-paragraph") {
                Document.removeParagraph();
            }

            // local execution records

            if (record.command === "bold") {
                Commands.Bold.execute();
            }

            if (record.command === "italic") {
                Commands.Italic.execute();
            }

            if (record.command === "strikethrough") {
                Commands.Strikethrough.execute();
            }

            if (record.command === "header") {
                Commands.Header.execute(record.metadata.size);
            }

            if (record.command === "ordered-list") {
                Commands.OrderedList.execute();
            }

            if (record.command === "unordered-list") {
                Commands.UnorderedList.execute();
            }

            if (record.command === "blockquote") {
                Commands.Blockquote.execute();
            }

            if (record.scope === "local") Document.update();
        };

        setTimeout(execute);
    }
}
